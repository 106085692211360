import React from 'react';
import {marked} from 'marked';
import ReactMarkdown from 'react-markdown'

const Answer = ({ content }) => {
  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">            
          <ReactMarkdown>{content}</ReactMarkdown>

    </div>
  );
};

export default Answer;
