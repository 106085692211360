import React, { useState } from "react";
import axios from "axios";
import Answer from "./Answer";

const FormPage = () => {
  const [fileLink1, setFileLink1] = useState("");
  const [fileLink2, setFileLink2] = useState(null);
  const [fileLink3, setFileLink3] = useState(null);

  const [primaryKeyword, setPrimaryKeyword] = useState("");
  const [secondaryKeywords, setSecondaryKeywords] = useState("");
  const [select1, setSelect1] = useState("");
  const [select2, setSelect2] = useState("");
  const [desiredWordCount, setDesiredWordCount] = useState(0);
  const [targetAudience, setTargetAudience] = useState("");
  const [targetBrand, setTargetBrand] = useState("");
  const [contentType, setContentType] = useState("");

  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        prompt: `Tell me a joke`,
      };
      setLoading(true);
      let contents = "";
      let firstContent1,firstContent2,firstContent3 = "";

       firstContent1 = fileLink1;
      
      if (fileLink2) {
         firstContent2 = fileLink2;
      }

      if (fileLink3) {
         firstContent3 = fileLink3;
      }

      contents = `${firstContent1}, ${firstContent2}, ${firstContent3}`;
      
      const apiResponse = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o",
          messages: [{ role: "user", content: `
              Task:
              Review the content from the following contents in the below, and rewrite these in an article that is optimised for ranking in Google search engine. Your output should include the meta title, meta description, page title, and the body of text with relevant headers and bodies of text.
              Instructions:
              Re-write the content from these pages into a new article, with the purpose of ranking on Google Search Engine. The target keyword the article should rank for is ${primaryKeyword}, and should also rank for ${secondaryKeywords}. We want the article to be written in ${select2}, and show for search results in ${select1}. The length of the article should be ${desiredWordCount} words long with sufficient paragraphs to make the article easy for readability, and the target audience should be ${targetAudience}. Write the content from a human perspective as much as possible.
              Rules:
              Write this article in the following style ${contentType}. When re-writing the article, it should be optimised using the latest best practices for SEO, particularly those from Google, in order to rank in Google search engine. Look at the Page Title, Meta Description, Headers, Paragraph and Body content only from the URLs you are reviewing.               
              The text needs to be ${desiredWordCount} long.
              The article needs to be written from the perspective of the brand ${targetBrand}. Remove any reference to other brands mentioned in the URLs.
              
              Contents : ${contents}              
            ` }],
        },
        {
          headers: {
            Authorization: `Bearer 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`,
            "Content-Type": "application/json",
          },
        }
      );
      setAnswer(apiResponse.data.choices[0].message.content);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching file or sending data:", error);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
      <form
        className="bg-white p-6 rounded shadow-md w-full max-w-4xl"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl mb-4 font-bold text-gray-700">
          Create a Content
        </h2>

        <div className="mb-4">
          <label className="block text-gray-700">Content Links</label>
          <input
            type="text"
            value={fileLink1}
            onChange={(e) => setFileLink1(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter content link"
            required
          />
          <input
            type="text"
            value={fileLink2}
            onChange={(e) => setFileLink2(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter content link"
          />
          <input
            type="text"
            value={fileLink3}
            onChange={(e) => setFileLink3(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter content link"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Primary Keyword</label>
          <input
            type="text"
            value={primaryKeyword}
            onChange={(e) => setPrimaryKeyword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter primary keyword"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Secondary Keywords: Allows multiple keywords (comma separated)
          </label>
          <input
            type="text"
            value={secondaryKeywords}
            onChange={(e) => setSecondaryKeywords(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="keyword1,keyword2..."
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Target Country</label>
          <select
            value={select1}
            onChange={(e) => setSelect1(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            required
          >
            <option value="">Choose a country</option>
            <option value="Turkey">Turkey</option>
            <option value="India">India</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Target Language</label>
          <select
            value={select2}
            onChange={(e) => setSelect2(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            required
          >
            <option value="">Choose a language</option>
            <option value="Turkish">Turkish</option>
            <option value="English">English</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Desired Word Count</label>
          <input
            type="number"
            max={3000}
            value={desiredWordCount}
            onChange={(e) => setDesiredWordCount(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter desired word count 0 - 3000"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Target Brand</label>
          <input
            type="text"
            value={targetBrand}
            onChange={(e) => setTargetBrand(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter target brand"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Target Audience</label>
          <input
            type="text"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter target audience"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Content Type</label>
          <input
            type="text"
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter content type"
            required
          />
        </div>

        {loading && <text>Loading...</text>}
        {!loading && (
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
          >
            Submit
          </button>
        )}
      </form>
      {answer && (
        <Answer content={answer} />
      )}
    </div>
  );
};

export default FormPage;
