import React from 'react';
import FormPage from './Form';

function App() {
  return (
    <div>
      <FormPage />
    </div>
  );
}

export default App;
